export const maxDuration = 60;

import { ApolloClient, InMemoryCache } from "@apollo/client";
import React from "react";
import PDPPage from "../../templates/ProductDetailsStack/ProductDetailsStack";
import { ProductQuery } from "../../utils/ssr/product_query";

const transformStack = stack => {
  return stack.map(el => {
    const amapiComponent = `AMAPI_${el.__typename}`;
    return { ...el, __typename: amapiComponent };
  });
};

// export default withLayout(ProductDetailsStack);
const SSRPDPPage = ({
  serverData: { product, vSlug, pSlug },
  pageContext,
}: any) => {
  if (product.stackPage?.stack) {
    product.stackPage.stack = transformStack(product.stackPage.stack);
  } else {
    product.stackComponents = transformStack(product.stackComponents);
  }

  return (
    <PDPPage
      pageContext={{ ...pageContext, ...{ variantSlug: vSlug } }}
      data={{
        alamedaapi: {
          product,
          catalogs: [],
          outOfStockNotificationRequest: {},
        },
      }}
    />
  );
};

export default SSRPDPPage;

const fetchPage = async ({ query: { slug } }) => {
  let [pSlug, vSlug] = slug.split("/");

  const apolloClient = new ApolloClient({
    uri: process.env.GATSBY_ALAMEDA_API_ENDPOINT,
    cache: new InMemoryCache(),
  });

  const result = await apolloClient.query({
    query: ProductQuery,
    variables: { productSlug: pSlug, locale: "en" },
  });

  const { products } = result.data;

  return {
    product: products[0],
    pSlug,
    vSlug,
  };
};

export async function getServerData(context: any) {
  let props = await fetchPage(context);
  return { props };
}
